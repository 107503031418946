<template>
  <div class="outContainer">
    <!-- 搜索栏 -->
    <div class="search">
        <el-cascader placeholder="所属商户 / 所属小区" v-if="userInfo.roleLevel < 5"
          class="each"
          clearable
          v-model="unitId"
          :options="managerUnitOptions"
          @change="chooseMerchant"
          :props="props"
        ></el-cascader>
        <el-select placeholder="安装小区" class='each' v-model="searchInfo.param.unitId" clearable  v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
          <el-option
            v-for="item in unitOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select placeholder="是否在线" class='each' v-model="searchInfo.param.isOnline" clearable>
          <el-option
            v-for="item in onlineOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select placeholder="是否有充电桩" class='each' v-model="searchInfo.param.isHaveChargingPile" clearable>
          <el-option
            v-for="item in isHaveChargingPileOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-input placeholder="设备编号" class="each" v-model="searchInfo.param.id"></el-input>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="openDrawer(null,'add')">新增</el-button>
    </div>
    <!-- 表格 -->
    <div class="tableContainer">
      <el-table
       height="100%"
       :data="tableList">
        <el-table-column align="center" label="设备编号" prop="id">
        </el-table-column>
        <el-table-column align="center" label="设备类型" prop="typeName">
        </el-table-column>
        <el-table-column align="center" label="安装小区" prop="unitName">
        </el-table-column>
        <el-table-column align="center" label="车位具体位置" prop="location">
        </el-table-column>
        <el-table-column align="center" label="版本" prop="version">
          <template slot-scope="scope">
            <p>硬件：{{ scope.row.hardwareVersion ? scope.row.hardwareVersion : '--' }}</p>
            <p>软件：{{ scope.row.softwareVersion ? scope.row.softwareVersion : '--'}}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否在线" prop="isOnlineName">
        </el-table-column>
        <el-table-column align="center" label="地锁状态" prop="isLockName">
        </el-table-column>
        <el-table-column align="center" label="是否停放车辆" prop="isParkingName">
        </el-table-column>
        <el-table-column align="center" label="是否有充电桩" prop="isHaveChargingPile">
          <template slot-scope="scope">
            {{ scope.row.isHaveChargingPile == 0 ? '无' : '有' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTimeStr">
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="270">
          <template slot-scope="scope">
            <p style="margin-bottom: 5px;">
              <el-button size="mini" @click="toChild(scope.row,'/device/floorLock/communication')">通讯记录</el-button>
              <el-button size="mini" @click="toChild(scope.row,'/device/floorLock/appointRecords')">预约记录</el-button>
            </p>
            <p>
              <el-button size="mini" @click="handleLock(scope.row)">{{ scope.row.isLock == 0 || scope.row.isLock == 2 ? '上锁' : '解锁' }}</el-button>
              <el-button size="mini" @click="openDrawer(scope.row,'edit')">编辑</el-button>
              <el-button size="mini" @click="handleDel(scope.row,'edit')">删除</el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    <!-- 新增、编辑 -->
    <el-drawer :title="row ? '编辑' : '新增'" :visible.sync="addDrawer" size="50%">
      <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
    </el-drawer>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import add from './add.vue';
import { getNeighbourhoodList,getMerchantList,getFloorLockList,delFloorLock,unlockFloorLock,lockFloorLock } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
        userInfo:JSON.parse(localStorage.getItem('userInfo')), // 当前登录用户信息
        tableList:[],// 表格数据
        searchInfo:{ // 筛选条件
            pageNo:1,
            pageSize:10,
            total:0,
            param:{
                merchantId:"",
                unitId:"",
                isOnline:"",
                id:"",
            }
        },
        unitId:"", // 筛选小区id
        managerUnitOptions:[], // 商户、小区联动选项
        unitOptions:[], // 小区选项
        onlineOptions:[ // 是否在线选项
          {id:1,name:'在线'},
          {id:2,name:'离线'},
        ],
        isHaveChargingPileOptions:[ // 是否有充电桩
          {id:0,name:'没有'},
          {id:1,name:'有'}
        ],
        props: { // 所属商户、小区 联动选择框配置
            value:'id',
            label:'name',
            lazy: true,
            checkStrictly:true,
            lazyLoad (node, resolve) {
            const { level } = node;
            console.log(node,987)
            setTimeout(() => {
                let data = {
                pageNo:1,
                pageSize:1000,
                param:{
                    name:null,
                    merchantId:node.value,
                }
                }
                if(level == 1){
                getNeighbourhoodList(data).then(res=>{
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    let nodes = [];
                    res.data.list.forEach(element=>{
                        nodes.push({
                        id: element.id,
                        name: element.name,
                        leaf: level >= 1,
                        children:null,
                        })
                    })
                    // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    resolve(nodes);
                })
                console.log(node,908)
                }
            }, 1000);
        }
        },
        row:null, // 当前操作数据
        addDrawer:false, // 控制新增、编辑弹框是否显示
    }
  },

  components: {
    Pagination,
    add,
  },

  computed: {},

  mounted() {
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
    this.init();
  },

  methods: {
    // 获取小区列表
    getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 初始化获取地锁列表
    async init(){
      await getFloorLockList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element=>{
            element.createTimeStr = timeChange(element.createTime,'seconds')
          })
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    },
    // 条件筛选事件
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = {
          merchantId:"",
          unitId:"",
          isOnline:"",
          id:"",
        };
        this.unitId = [];
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 打卡侧边弹框
    openDrawer(row){
      this.row = row;
      this.addDrawer = true;
    },
    // 关闭弹框
    handleClose(){
      this.addDrawer = false;
      this.row = null;
      this.init();
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
    },
    // 前往子页面
    toChild(row,path){
      this.$router.push({path:path, query: {id: row.id}});
    },
    // 上锁、解锁
    handleLock(row){
      this.$confirm('确认进行该操作吗？').then(()=>{
        if(row.isLock == 0 || row.isLock == 2){
          lockFloorLock({param:row.id}).then(res=>{
            this.$message({
              message:res.success ? '操作成功' : res.msg,
              type:res.success ? 'success' : 'fail'
            })
            this.init();
          })
        }else{
          unlockFloorLock({param:row.id}).then(res=>{
            this.$message({
              message:res.success ? '操作成功' : res.msg,
              type:res.success ? 'success' : 'fail'
            })
            this.init();
          })
        }
      })
    },
    // 删除
    handleDel(row){
        this.$confirm('确认删除吗？').then(()=>{
          delFloorLock({param:row.id}).then(res=>{
            this.$message({
              message:res.success ? '删除成功' : res.msg,
              type:res.success ? 'success' : 'fail'
            })
            this.init();
          })
        })
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped></style>
